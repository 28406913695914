// console.log("Document os");
// $(document).ready(function() {
  // try {
    // console.log("start is printing");
    setTimeout(function () { 
      // console.log("setTimeout is working");
      $(".equalH").each(function () {
        
        // console.log("equalH is working");
        var $sameHeightChildren = $(this).find(".inner");
        var maxHeight = 0;
        $sameHeightChildren.each(function () {
          // console.log("inner is started");
          maxHeight = Math.max(maxHeight, $(this).outerHeight())+5;
        });
        $sameHeightChildren.css({ height: maxHeight + "px" });
      }); 
      // console.log("equalH loop finished");
       }, 0);    
        
$(".nav li").on("click", function () {
  $(".nav li").removeClass("active");
  $(this).addClass("active");
});
var size;

function mainFunction() {
  $(document).ready(function () {
  // $(function () {  
    if (/Android|iPhone|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      size = 45
    } else {
      size = 50
    }
    // $(".chart1").easyPieChart({
    //   size: size,
    //   barColor: "#5d5e5e",
    //   scaleColor: false,
    //   lineWidth: 2,
    //   lineCap: "circle",
    // });
    // $(".chart2").easyPieChart({
    //   size: size,
    //   barColor: "#74b571",
    //   scaleColor: false,
    //   lineWidth: 2,
    //   lineCap: "circle",
    // });
    // $(".chart3").easyPieChart({
    //   size: size,
    //   barColor: "#d2ba35",
    //   scaleColor: false,
    //   lineWidth: 2,
    //   lineCap: "circle",
    // });
    // $(".chart4").easyPieChart({
    //   size: size,
    //   barColor: "red",
    //   scaleColor: false,
    //   lineWidth: 2,
    //   lineCap: "circle",
    // });
    // $(".nodata").easyPieChart({
    //   size: size,
    //   barColor: "#a8a8a8",
    //   scaleColor: false,
    //   lineWidth: 2,
    //   lineCap: "circle",
    // }); 
    $(window).scroll(function(){ 
      if ($(this).scrollTop() > 100) { 
          $('#scroll').fadeIn(); 
      } else { 
          $('#scroll').fadeOut(); 
      } 
  }); 
  
  setTimeout(function () { 
    // console.log("chartJs is printing");
    $(".equalH").each(function () {
      // console.log("finding equalH");
      var $sameHeightChildren = $(this).find(".inner");
      var maxHeight = 0;
      $sameHeightChildren.each(function () {
        maxHeight = Math.max(maxHeight, $(this).outerHeight())+2;
      });
      $sameHeightChildren.css({ height: maxHeight + "px" });
    }); 
     }, 1);
    // $('.panel-group').on('hidden.bs.collapse', toggleIcon);
    // $('.panel-group').on('shown.bs.collapse', toggleIcon);
    //  $('.panel-group').on('shown.bs.collapse', toggleIcon, function () { 
    //   // $('#accordion .show').collapse('hide');
    //   // $(this).collapse('show');
    //  });
  }); 
}
// function toggleIcon(e) {
//   console.log(e);
//   console.log(e.target);
//   $(e.target)
//       .prev('.panel-heading')
//       .find(".more-less")
//       .toggleClass('glyphicon-plus glyphicon-minus');
// }
function initDateRangepicker() {
  var min = new Date();
  min.setDate(min.getDate() - 15);
  $(".daterangepicker").daterangepicker({
    initialText: "Select date range...",
    presetRanges: false,
    applyOnMenuSelect: false,
    datepickerOptions: {
      maxDate: 0,
      numberOfMonths: 2,
      minDate: min,
    },
  });
  let element = document.getElementsByClassName(
    "comiseo-daterangepicker-triggerbutton"
  );
  while (element[0].firstChild) {
    element[0].removeChild(element[0].firstChild);
  }
  element[0].classList.add("ui-button");
  element[0].classList.add("ui-widget");
  element[0].classList.add("ui-state-default");
  element[0].classList.add("ui-corner-all");
  element[0].classList.add("ui-button-text-icon-secondary");
  var span = document.createElement("span");
  span.classList.add("ui-button-text");
  span.innerHTML = "Select date range...";
  var span2 = document.createElement("span");
  span2.classList.add("ui-button-icon-secondary");
  span2.classList.add("ui-icon");
  span2.classList.add("ui-icon-triangle-1-s");
  var html = span + span2;
  element[0].appendChild(span);
  element[0].appendChild(span2);
  $(document).on("click", ".ui-priority-primary", function () {
    var startDate = $(".daterangepicker").daterangepicker("getRange").start;
    var EndDate = $(".daterangepicker").daterangepicker("getRange").end;
    if (startDate != undefined && EndDate != undefined) {
      var date = GetFormattedDate(startDate) + "-" + GetFormattedDate(EndDate);
      document.getElementsByClassName("ui-button-text")[0].innerHTML = date;
      const oneDay = 60 * 1000;
      document.getElementById("daterangepicker").value = Math.round(
        Math.abs((startDate - EndDate) / oneDay)
      );
      window.dispatchEvent(
        new CustomEvent("ngDateChanged", {
          isTrusted: true
        })
      );
    }

    // console.log(document.getElementById("daterangepicker").value);
    // var $myInput = $('#daterangepicker').on('change', getSelectedValue);
    // $myInput.trigger('change');
  });
  $(document).on("click", ".ui-priority-secondary", function () {
    document.getElementsByClassName("ui-button-text")[0].innerHTML =
      "Select date range...";
  });
}

function GetFormattedDate(date) {
  var todayTime = new Date(date);
  var month = todayTime.getMonth() + 1;
  var day = todayTime.getDate();
  var year = todayTime.getFullYear();
  if (month < 10) {
    return day + "/0" + month + "/" + year;
  } else {
    return day + "/" + month + "/" + year;
  }
}
window.addEventListener("ngFix", function (e) {
  setTimeout(function () {
    mainFunction();
    //$(".add").addClass("_absolute");
  }, 10);
});
window.addEventListener("ngFixDatePicker", function (e) {
  setTimeout(function () {
    initDateRangepicker();
  }, 10);
});
window.addEventListener("destroyDatePicker", function (e) {
  if ($(".daterangepicker").daterangepicker()) {
    $(".daterangepicker").daterangepicker("clearRange");
    $(".daterangepicker").daterangepicker("close");
  }
});

document.addEventListener("selectionFired", function (e) { 
  setTimeout(function () {
    $("."+e.detail.parent).each(function () { 
       var $sameHeightChildren = $(this).find("."+e.detail.child);
       var maxHeight = 0;
       var margin='margin-bottom';
       $sameHeightChildren.each(function () {
        $("."+e.detail.child).css({
          height: ''
        }); 
         maxHeight = Math.max(maxHeight, $(this).outerHeight())+5;
       
       });
       $sameHeightChildren.css({
         height: maxHeight + "px"
       });
     
     }); 
     }, 5);
     
   });
   document.addEventListener("activateTabs", function (e) {  
        let id =e.detail.id;
        let type= e.detail.type; 
        var paramElemList=document.getElementsByClassName("allParameters");
        var compElemList=document.getElementsByClassName("allComponents");
        var tabs=document.getElementsByClassName("tab-content"); 
        if(tabs.length>0){
          for(var e=0;e<compElemList.length;e++){
            tabs[e].classList.remove("selectedCur");
          }  
        }
        if(paramElemList.length>0 && compElemList.length>0){
          for(var e=0;e<paramElemList.length;e++){ 
            paramElemList[e].style.display='none';
          }
          for(var e=0;e<compElemList.length;e++){ 
            compElemList[e].style.display='none';
          } 
        }
        let pid="allparams"+id;
        let cid="allcomps"+id;
        document.getElementById(pid).style.display="inline-block";
        document.getElementById(cid).style.display="inline-block";
        if(type=='parent'){
          var elm=document.getElementsByClassName("current"); 
          if(elm.length>1){
            var currentActiveElemTpe = elm[1].getAttribute("data-type");
            if(currentActiveElemTpe=="parameters"){
              jQuery('.enviornment-single').removeClass('current');
              jQuery('.tab-content').removeClass('current'); 
              jQuery("#tab"+id).toggleClass('current'); 
              var elemParam=document.getElementsByClassName("params"+id);
              elemParam[0].classList.add("current");
            }else if(currentActiveElemTpe=="components"){
              jQuery('.enviornment-single').removeClass('current');
              jQuery('.tab-content').removeClass('current'); 
              jQuery("#tab"+id).toggleClass('current'); 
              var elemCon=document.getElementsByClassName("comps"+id);
              elemCon[0].classList.add("current");
            }
          }else{
            jQuery('.enviornment-single').removeClass('current');
            jQuery('.tab-content').removeClass('current'); 
            jQuery("#tab"+id).toggleClass('current'); 
            var elemParam=document.getElementsByClassName("params"+id);
            elemParam[0].classList.add("current");
          }  
        }else if(type=='params'){
          jQuery('.enviornment-single').removeClass('current');
              jQuery('.tab-content').removeClass('current'); 
              jQuery("#tab"+id).toggleClass('current'); 
          var elemParam=document.getElementsByClassName("params"+id);
          elemParam[0].classList.add("current");
        }else if(type=='comps'){
          jQuery('.enviornment-single').removeClass('current');
              jQuery('.tab-content').removeClass('current'); 
              jQuery("#tab"+id).toggleClass('current'); 
          var elemCon=document.getElementsByClassName("comps"+id);
          elemCon[0].classList.add("current");
        }  
        if(type=='deactiveParams'){
          jQuery("#tab"+id).removeClass("current");
          var elemParam=document.getElementsByClassName("params"+id);
          elemParam[0].classList.remove("current");
          jQuery("#tab"+id).addClass("selectedCur");
        }
        if(type=='deactiveComps'){
          jQuery("#tab"+id).removeClass("current");
          jQuery("#tab"+id).addClass("selectedCur");
          var elemParam=document.getElementsByClassName("comps"+id);
          elemParam[0].classList.remove("current");
        }
    
		//jQuery("#"+tab_id).addClass('current');
  });
 

  document.addEventListener("accordionState", function () { 
    setTimeout(function () {
    var paramElemList=document.getElementsByClassName("allParameters");
    var compElemList=document.getElementsByClassName("allComponents");
    var tabs=document.getElementsByClassName("tab-content");

    if(paramElemList.length>0 && compElemList.length>0){
      for(var e=0;e<paramElemList.length;e++){
        if(e!=0)
        paramElemList[e].style.display='none';
      }
      for(var e=0;e<compElemList.length;e++){
        if(e!=0)
        compElemList[e].style.display='none';
      }  
    }
    if(tabs.length>0){
      for(var e=0;e<compElemList.length;e++){
        if(e==0)
        tabs[e].classList.add("selectedCur");
      }  
    }
  }, 1);
});
document.addEventListener("tabs", function(e){
 
    $('.nav li').removeClass('active');
    var elem=document.getElementById(e.detail.id);
    elem.classList.add('active');
    //$("#"+e.detail.id).addClass('active'); 
});
  // } catch (error) {
  //   console.error(error);
  // } 
// });
